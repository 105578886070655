import './App.css';
import React from 'react';

import {Route, Routes,} from "react-router-dom";

import TopBar from "./Component/TopBar";
import Main from "./Page/Main";
import Footer from "./Component/Footer";
import About from "./Page/About";
import Contact from "./Page/Contact";
import withStyles from "react-jss";
import {withRouter} from "./withRouter";
import {withCookies} from "react-cookie";
import {withTranslation} from "react-i18next";
import {createTheme, ThemeProvider} from "@mui/material";
import Develop from "./Page/Develop";
import Tos from "./Page/Tos";
import axios from "axios";

const classes = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: "#000",
        textDecoration: "none",
        display: "block",
        width: "max-content",
    },
    footerCell: {
        flex: 1,
        minWidth: 150,
        marginBottom: 15,
    }
});

class App extends React.Component {

    constructor(prop) {
        super(prop);
        this.props = prop;
        this.state = {
            topBarColor: "rgba(0, 0, 0, 0)",
            account: null,
        }
        this.getAcceptLanguage.bind(this);
        this.changeLanguage.bind(this);
    }

    componentDidMount() {
        this.getAcceptLanguage()

        axios.get('https://account.zentring.net/api/getUser?withInformation', {
            withCredentials: true
        }).then((response) => {
            if (response.data.status) {
                this.setState({account: response.data.data})
            }
        }).catch(function (error) {
        }).then(function () {
        });
    }

    getAcceptLanguage = () => {
        const {i18n, cookies} = this.props;
        let l = cookies.get("overrideLanguage")
        if (i18n.options.resources[l]) {
            this.changeLanguage(l)
            return
        }
        let lang = navigator.languages.find((v, k) => {
            return i18n.options.resources[v]
        })
        console.log(lang)
        this.changeLanguage(lang)

    }

    changeLanguage = (languageCode) => {
        const {i18n, cookies} = this.props;
        i18n.changeLanguage(languageCode)
        if (languageCode !== undefined) {
            if (languageCode !== this.state.language && this.state.language !== "") {
                cookies.set("overrideLanguage", languageCode)
            }
            this.setState({language: languageCode})
        }
    }

    render() {
        const defaultTheme = createTheme({
            palette: {
                // mode: "dark",
            },
        });
        return (
            <>
                <ThemeProvider theme={defaultTheme}>
                    <TopBar background={this.state.topBarColor} account={this.state.account}/>
                    <Routes>
                        <Route path={"/about"} element={<About onUpdateColor={(color) => {
                            this.setState({topBarColor: color})
                        }}/>}/>
                        <Route path={"/contact"}
                               element={<Contact account={this.state.account} onUpdateColor={(color) => {
                                   this.setState({topBarColor: color})
                               }}/>}/>
                        <Route path={"/tos"} element={<Tos onUpdateColor={(color) => {
                            this.setState({topBarColor: color})
                        }}/>}/>
                        <Route path={"/develop"} element={<Develop onUpdateColor={(color) => {
                            this.setState({topBarColor: color})
                        }}/>}/>
                        <Route path={"/"} element={<Main onUpdateColor={(color) => {
                            this.setState({topBarColor: color})
                        }}/>}/>
                    </Routes>
                    <Footer/>
                </ThemeProvider>
            </>
        );
    }
}

export default withTranslation()(withCookies(withRouter(withStyles(classes)(App))));
