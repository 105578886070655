import {useLocation, useNavigate, useParams} from "react-router-dom";

export function withRouter(Children) {
    return (props) => {

        const match = {params: useParams()};
        const navigate = useNavigate()
        const location = useLocation()
        return <Children {...props} match={match} navigate={navigate} location={location}/>
    }
}