import React from 'react';
import {Link} from "react-router-dom";
import logo from "../assets/icon-v1.svg";
import {withRouter} from "../withRouter";
import withStyles from "react-jss";
import {
    Avatar, Box,
    Button,
    Card,
    CardContent, Drawer, IconButton,
    List,
    ListItemAvatar,
    ListItemButton, ListItemText,
    Typography
} from "@mui/material";
import {red} from "@mui/material/colors";
import {withTranslation} from "react-i18next";
import axios from "axios";
import MenuIcon from '@mui/icons-material/Menu';

const classes = theme => ({
    navBar: {
        background: "gray",
        height: 50,
    },
    linkDark: {
        color: "#000",
        textDecoration: "none",
        verticalAlign: "middle",
        lineHeight: "50px",
        marginLeft: 10,
        marginRight: 10,
        display: "inline-flex",
        alignItems: "center",
        height: 50,
        justifyContent: "center",
    },
    linkLight: {
        color: "white",
        textDecoration: "none",
        verticalAlign: "middle",
        lineHeight: "50px",
        marginLeft: 10,
        marginRight: 10,
        display: "inline-flex",
        alignItems: "center",
        height: 50,
        justifyContent: "center",
    },
    profileImage: {
        width: 30,
        borderRadius: "50%",
    },
    card: {
        position: "fixed",
        zIndex: 9999999,
        right: 10,
        top: 60,
    },
    hidden: {
        display: "none"
    }
});
// const logoRatio = 1020 / 258;
const logoRatio = 512 / 512;

let isInit = false;

const menu = [
    {
        name: "首頁",
        to: "/",
    },
    {
        name: "軟硬體開發",
        to: "/develop"
    },
    {
        name: "聯絡我們",
        to: "/contact"
    }, {
        name: "雲端計算",
        to: "//hosting.zentring.net"
    }

]


class TopBar extends React.Component {

    constructor(prop) {
        super(prop);
        this.props = prop;
        this.state = {
            isProfileCardOpen: false,
            isMenuOpen: false,
        }
    }


    componentDidMount() {

        if (!isInit) {
            window.profileRange = document.getElementById("2qJcr")
            window.profileImage = document.getElementById("x1NTzkx7")
            window.addEventListener("click", (e) => {
                if (!window.profileRange.contains(e.target) && !window.profileImage.contains(e.target)) {
                    this.setState({
                        isProfileCardOpen: false,
                    })
                }
            })
        }
        isInit = true
    }

    render() {

        const {classes} = this.props;
        const {t} = this.props.i18n

        let img = "";
        if (this.props.account && this.props.account.users && this.props.account.users[this.props.account.activeUser]) {
            img = this.props.account.users[this.props.account.activeUser].image
        } else {
            img = "https://account.zentring.net/none.png"
        }

        return (
            <>
                <Drawer open={this.state.isMenuOpen} onClose={() => {
                    this.setState({isMenuOpen: false})
                }}
                        PaperProps={{
                            background: "white"
                        }}>
                    <List sx={{width: 250}}>

                        {
                            menu.map((item, key) => {
                                return <ListItemButton component={Link} key={key}
                                                       to={item.to}
                                                       onClick={() => {
                                                           this.setState({isMenuOpen: false})
                                                       }}>
                                    <ListItemText primary={item.name}/>
                                </ListItemButton>
                            })
                        }
                    </List>
                </Drawer>
                <div>
                    <div style={{
                        position: "fixed",
                        height: 50,
                        width: "100%",
                        zIndex: 999,
                        background: this.props.background,
                        transition: "background 0.5s",
                    }}>
                        <div style={{
                            height: 50,
                            lineHeight: "50px",
                            width: "100%",
                        }}>
                            <Box sx={{
                                height: 50, // 8 times, 40px and 16px
                                marginLeft: {sm: 5, xs: 2},
                                fontSize: 0
                            }}>{/*without button is 40*/}
                                <IconButton edge="start"
                                            sx={{
                                                display: {sm: "none", xs: "inline-block"},
                                                color: this.props.background === "rgba(0, 0, 0, 0)" ? "white" : "black",
                                            }}
                                            onClick={() => {
                                                this.setState({isMenuOpen: true})
                                            }}>
                                    <MenuIcon/>
                                </IconButton>
                                <Link to={"/"} className={classes.linkDark}>
                                    <div style={{
                                        backgroundImage: "url(" + logo + ")",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        display: "inline-block", height: 40 * 0.8, width: 50 * logoRatio * 0.8,
                                        verticalAlign: "middle",
                                    }}/>
                                </Link>

                                <Box sx={{display: {xs: "none", sm: "inline-block"}}}>
                                    {
                                        menu.map((item, key) => {
                                            return <Link to={item.to} key={key}
                                                         className={this.props.background === "rgba(0, 0, 0, 0)" ? classes.linkLight : classes.linkDark}>
                                                <span style={{marginLeft: 10, fontSize: "initial",}}>{item.name}</span>
                                            </Link>
                                        })
                                    }
                                </Box>

                                <div style={{float: "right", height: 50}}>
                                    <a className={classes.linkDark} href={"//account.zentring.net"} id={"x1NTzkx7"} onClick={(e) => {
                                        this.setState({
                                            isProfileCardOpen: !this.state.isProfileCardOpen,
                                        })
                                        e.preventDefault();
                                        e.stopPropagation();
                                        return false
                                    }}>
                                        <img src={img}
                                             alt={"Account"}
                                             style={{flexDirection: "column",}}
                                             className={classes.profileImage}/>
                                    </a>
                                </div>
                            </Box>
                        </div>
                    </div>
                    <div id={"2qJcr"}
                         className={[classes.card, this.state.isProfileCardOpen ? "" : classes.hidden].join(" ")}>
                        <Card className={classes.root} style={{width: 300}}>
                            {this.props.account && this.props.account.users[this.props.account.activeUser] ?
                                <CardContent>
                                    <div style={{display: "inline-flex"}}>
                                        <Avatar src={this.props.account.users[this.props.account.activeUser].image}
                                                style={{display: "inline-block", width: 30, height: 30}}/>
                                        <span style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            lineHeight: "30px",
                                            marginLeft: 10,
                                        }}>{this.props.account.users[this.props.account.activeUser].displayName}</span>
                                    </div>
                                    <Typography className={classes.pos} color="textSecondary">
                                        {this.props.account.activeUser}@zentring.net
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <Button component={"a"} href="//account.zentring.net"
                                                style={{color: "mediumpurple"}}>{t('Zentring account')}</Button>
                                        <Button onClick={() => {

                                            let form = new FormData();
                                            form.append("account", this.props.account.activeUser);

                                            axios.post('https://account.zentring.net/api/logout', form, {withCredentials: true})
                                                .then((response) => {
                                                    if (response.data.status) {
                                                        window.location.href = "https://account.zentring.net/v3/?from=6oHhAX&continue=" + encodeURI("https://console.zentring.net" + window.location.pathname)
                                                    } else {
                                                        console.log(response.data);
                                                    }
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                })
                                                .finally(function () {
                                                });
                                        }}
                                                style={{color: red[700]}}>{t('Sign out')}</Button>
                                    </Typography>
                                    <List>
                                        {Object.entries(this.props.account.users).filter(([k, v]) => k !== this.props.account.activeUser).map(([username, v]) => {

                                            return <ListItemButton key={username} onClick={(e) => {
                                                if (v.isLogin) {
                                                    axios.get('https://account.zentring.net/api/activeUser?username=' + username, {withCredentials: true})
                                                        .then((response) => {
                                                            if (response.data.status) {
                                                                window.location.reload()
                                                            } else {
                                                                console.log(response.data);
                                                            }
                                                        })
                                                        .catch(function (error) {
                                                            console.log(error);
                                                        })
                                                        .finally(function () {
                                                        });
                                                } else {
                                                    window.location.href = "https://account.zentring.net/v3/signin?user=" + username + "&continue=" + encodeURIComponent(window.location.href)
                                                }

                                            }}>
                                                <ListItemAvatar>
                                                    <Avatar alt={v.displayName}
                                                            src={v.image}/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={<>
                                                        <Typography
                                                            component="span"
                                                            variant="body1"
                                                            color="textPrimary"
                                                        >
                                                            {v.displayName}
                                                        </Typography>
                                                        <Typography sx={{fontStyle: 'italic'}}
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="textPrimary"
                                                        >
                                                            {v.isLogin ? "" : t("Logged out")}
                                                        </Typography>
                                                    </>}
                                                    secondary={username + "@zentring.net"}/>
                                            </ListItemButton>
                                        })}
                                    </List>

                                    <Typography variant="body2" component="p">
                                        <Button component={"a"} href="//account.zentring.net/v3/signin?from=yVOremK2"
                                                style={{color: "mediumpurple"}}>{t('Add another account')}</Button>
                                    </Typography>
                                </CardContent> : <CardContent>
                                    <div style={{display: "inline-flex"}}>
                                        <Avatar src={"https://account.zentring.net/none.png"}
                                                style={{display: "inline-block", width: 30, height: 30}}/>
                                        <span style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            lineHeight: "30px",
                                            marginLeft: 10,
                                        }}>{t("Not logged in")}</span>
                                    </div>
                                    <Typography variant="body2" component="p">
                                        <Button component={"a"} href="//account.zentring.net/v3/signin?from=888b2"
                                                style={{color: "mediumpurple"}}>{t('Log into Zentring')}</Button>
                                    </Typography>
                                </CardContent>}
                        </Card>
                    </div>
                </div>
            </>
        )
            ;
    }
}


export default withTranslation()(withRouter(withStyles(classes)(TopBar)));
