import React from 'react';
import monitor from "../assets/monitor.png";
import serverRack from "../assets/2U_server.svg";
import ZentringConsole from "../assets/ZentringConsole.png";
import {withRouter} from "../withRouter";
import {Box, Grid, Typography} from "@mui/material";
import withStyles from "react-jss";
import {withTheme} from "../withTheme";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const classes = theme => ({
    link: {
        color: "#000",
        textDecoration: "none",
        verticalAlign: "middle",
        lineHeight: "40px",
        display: "inline-block",
        marginLeft: 10,
        marginRight: 10,
    },
});

class Main extends React.Component {

    constructor(prop) {
        super(prop);
        this.props = prop;
        this.state = {
            monitorScaleFactor: 1,
        }
        this.updateMonitorImageScale.bind(this);
        this.cloudComputingSection = React.createRef()
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        window.onresize = () => {
            this.updateMonitorImageScale();
        }
        this.updateMonitorImageScale();

        window.onscroll = (e) => {
            let a = this.cloudComputingSection.current.getBoundingClientRect().top
            if (a < 50) {
                this.props.onUpdateColor("rgba(255, 255, 255, 1)")
            } else {
                this.props.onUpdateColor("rgba(0, 0, 0, 0)")
            }
        }
        window.onscroll(null)
    }

    updateMonitorImageScale = () => {
        // console.log(window.innerWidth);
        if (window.innerWidth < 600) {
            this.setState({monitorScaleFactor: 1})
        } else if (window.innerWidth < 900) {
            this.setState({monitorScaleFactor: 1.2})
        } else if (window.innerWidth < 1200) {
            this.setState({monitorScaleFactor: 1.5})
        } else if (window.innerWidth < 1536) {
            this.setState({monitorScaleFactor: 1.5})
        } else {
            this.setState({monitorScaleFactor: 2})
        }
    }

    componentWillUnmount() {
        // window.scrollTo(0, 0);
        window.onresize = null;
        window.onscroll = null;
    }

    render() {
        const {classes} = this.props;
        // console.log(this.props.theme.breakpoints.up('md'));
        return (
            <div>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: 650,

                        position: "relative",
                        "&:before": {
                            content: '""',
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundImage: "url(" + monitor + ")",
                            filter: "brightness(40%)",
                            left: 0, top: 0, bottom: 0, right: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: -1,
                            position: "absolute",
                        }
                    }}>
                    <Grid item sx={{}}>
                        <Typography variant={"h2"} sx={{
                            textAlign: "center",
                            textShadow: "1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000"
                        }} color={"white"}>
                            一條龍的服務
                        </Typography>
                        <Typography variant={"h4"} sx={{
                            mt: 2,
                            textAlign: "center",
                            textShadow: "1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000"
                        }} color={"white"}>
                            軟體開發、網站建置、亦或是嵌入式系統開發
                            <br/>
                            都能放心交給我們
                        </Typography>
                    </Grid>
                </Grid>

                <Box sx={{
                    backgroundColor: "#f5f5f5",
                }} ref={this.cloudComputingSection}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{height: 650,}}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="stretch"
                                sx={{
                                    backgroundColor: "#f5f5f5",
                                    height: "100%",
                                }}>
                                <Grid item>
                                    <Typography variant={"h2"} sx={{
                                        textAlign: "center",
                                    }} color={""}>
                                        雲端計算
                                    </Typography>
                                    <Typography variant={"h4"} sx={{
                                        mt: 2,
                                        textAlign: "center",
                                    }} color={""}>
                                        簡潔易懂的介面，讓您輕鬆管理伺服器或容器
                                    </Typography>
                                    <Box sx={{height: 20}}/>
                                    <Box sx={{width: "50%", minWidth: 300, margin: "auto"}}>
                                        <a style={{color: "black", width: "70%", margin: "auto", display: "block",}}
                                           className={classes.link}
                                           href={"https://hosting.zentring.net/"}>
                                            <div
                                                style={{
                                                    border: "1px solid rgba(0, 0, 0, .7)",
                                                    padding: "10px",
                                                    borderRadius: 10,
                                                    // maxWidth: "90%",
                                                }}>
                                                <Typography variant={"h4"} align={"center"}
                                                            style={{verticalAlign: "middle",}}>
                                                    <KeyboardArrowRightIcon
                                                        fontSize={"large"}
                                                        style={{verticalAlign: "middle",}}/>
                                                    看更多
                                                </Typography>
                                            </div>
                                        </a>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{height: 650,}}>
                            <Box sx={{}}>
                                <Box sx={{
                                    // position: "relative",
                                    width: "80%",
                                    height: 650,
                                    margin: "auto",
                                }}>
                                    <Box sx={{
                                        top: "50%",
                                        left: "50%",
                                        width: 340 * this.state.monitorScaleFactor,
                                        height: 165 * this.state.monitorScaleFactor,
                                        maxWidth: "900%",
                                        border: "solid 10px black",
                                        borderRadius: 3,
                                        position: "relative",
                                        transform: "translate(-50%, -50%)",
                                    }}>
                                        <Box sx={{
                                            position: "absolute",
                                            width: "40%",
                                            left: "50%",
                                            transform: "translate(-50%, 0)",
                                            height: "8%",
                                            bottom: "-20%",
                                            backgroundColor: "black",
                                        }}/>

                                        <Box sx={{
                                            position: "absolute",
                                            width: "10%",
                                            left: "50%",
                                            transform: "translate(-50%, 0)",
                                            height: 100,
                                            bottom: "-20%",
                                            backgroundColor: "black",
                                        }}/>

                                        <img src={ZentringConsole} alt={"Zentring cloud"}
                                             style={{
                                                 width: "100%",
                                                 userSelect: "none",
                                                 position: "relative",
                                             }}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>


                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: 650,
                        backgroundColor: "#f5f5f5",
                    }}>
                    <Grid item sx={{}}>
                        <Typography variant={"h2"} sx={{
                            textAlign: "center",
                        }}>
                            二手伺服器
                        </Typography>
                        <Typography variant={"h4"} sx={{
                            mt: 2,
                            textAlign: "center",
                            mb: 8,
                        }}>
                            來自各大廠商的二手伺服器，讓您以最優惠的價格擁有最強大的效能
                        </Typography>
                        <Box
                            sx={{

                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundImage: "url(" + serverRack + ")",
                                height: 200,
                                margin: 10
                            }}
                        ></Box>
                    </Grid>
                </Grid>
                <Box sx={{
                    height: 150,
                    backgroundImage: "linear-gradient(#f5f5f5, #FFFFFF)",
                }}>
                </Box>
            </div>
        );
    }
}

export default withTheme(withRouter(withStyles(classes)(Main)));
