import React from 'react';
import {withRouter} from "../withRouter";
import withStyles from "react-jss";
import {Box, Container, Grid, Typography} from "@mui/material";
import BuildingCanvas from "../Component/BuildingCanvas";

const classes = theme => ({
    link: {
        color: "#000",
        textDecoration: "none",
        verticalAlign: "middle",
        lineHeight: "40px",
        display: "inline-block",
        marginLeft: 10,
        marginRight: 10,
    },
});

class About extends React.Component {

    constructor(prop) {
        super(prop);
        this.props = prop;
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.onUpdateColor("rgba(255, 255, 255, 1)")
    }


    render() {
        return (
            <div>
                <div style={{height: 50}}/>
                <div style={{marginTop: 20, marginBottom: 20,}}>
                    <Container>
                        <Typography variant={"h4"}>
                            關於 Zentring
                        </Typography>
                        <Typography sx={{mt: 2}}>
                            於中華民國境內註冊為 天日科技有限公司（統編：96685590）。
                        </Typography>
                        <Typography sx={{mt: 2}}>
                            營業地址位於：高雄市苓雅區自強三路5號29樓之5。
                        </Typography>

                        <Box sx={{mt: 5}}/>

                        <Typography variant={"h4"}>
                            如何前往
                        </Typography>

                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <BuildingCanvas style={{width: "100%", height: 500}}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant={"h5"}>
                                    步行
                                </Typography>
                                <Typography sx={{mt: 2}}>
                                    由 自強三路 入口大門進入後，往右側搭乘往 12 樓轉階層之電梯
                                </Typography>
                                <Typography sx={{mt: 2}}>
                                    抵達 12 樓 日光購物廣場 後，往北側搭乘電梯至 29樓
                                </Typography>
                                <Typography sx={{mt: 2}}>
                                    辦公室位於進入門禁區後右手邊
                                </Typography>

                                <Box sx={{mt: 5}}/>

                                <Typography variant={"h5"}>
                                    駕車
                                </Typography>
                                <Typography sx={{mt: 2}}>
                                    由 新光路 停車場入口進場後，可停放於 B4 及 B5
                                </Typography>
                                <Typography sx={{mt: 2}}>
                                    搭乘設於靠中央側的兩部電梯即可抵達一樓大廳
                                </Typography>
                                <Typography sx={{mt: 2}}>
                                    （其餘電梯需繞行或無法抵達）
                                </Typography>
                                <Typography sx={{mt: 2}}>
                                    後續路徑與步行相同
                                </Typography>

                                <Box sx={{mt: 5}}/>

                                <Typography variant={"h5"}>
                                    輕軌 / 捷運
                                </Typography>
                                <Typography sx={{mt: 2}}>
                                    抵達輕軌 C8 高雄展覽館 / 捷運 R8 三多商圈
                                </Typography>
                                <Typography sx={{mt: 2}}>
                                    沿新光路行走後，可抵達自強三路從大門進入
                                </Typography>


                            </Grid>
                        </Grid>


                        <Box sx={{mt: 5}}/>

                        <Typography variant={"h4"}>
                            紀事
                        </Typography>

                        <Typography variant={"h5"} sx={{marginTop: 4,}}>
                            2024 年
                        </Typography>
                        <Typography variant={"h6"}>
                            4 月 17 日，登記 天日科技有限公司。
                        </Typography>

                        <Typography variant={"h5"} sx={{marginTop: 2,}}>
                            2021 年
                        </Typography>
                        <Typography variant={"h6"}>
                            籌備伺服器及軟體等相關事宜。
                        </Typography>
                    </Container>
                </div>
                <Box sx={{height: 10}}/>
            </div>
        );
    }
}

export default withRouter(withStyles(classes)(About));
