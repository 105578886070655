import React from 'react';
import {withRouter} from "../withRouter";
import withStyles from "react-jss";
import {Box, Container, Typography} from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Trans} from "react-i18next";

const classes = theme => ({
    link: {
        color: "#000",
        textDecoration: "none",
        verticalAlign: "middle",
        lineHeight: "40px",
        display: "inline-block",
        marginLeft: 10,
        marginRight: 10,
    },
});

const date = "2024/07/03"

class Tos extends React.Component {

    constructor(prop) {
        super(prop);
        this.props = prop;
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.onUpdateColor("rgba(255, 255, 255, 1)")
    }


    render() {
        const {classes} = this.props;
        return (
            <div>
                <div style={{height: 50}}/>
                <div style={{marginTop: 20, marginBottom: 20,}}>
                    <Container>
                        <Typography variant={"h4"}>
                            服務條款
                        </Typography>

                        <Typography variant={"body1"} align={"left"} color={"textSecondary"}>
                            <Trans i18nKey="Last updated on">
                                Last updated on {date}
                            </Trans>
                        </Typography>

                        <Box sx={{mt: 1}}/>

                        <Typography variant={"body1"}>
                            為了讓您能夠安心使用 Zentring 各服務
                            （包含但不限於位於 *.zentring.net 和 *.zhost.app 子網域的服務）與資訊，<br/>
                            特此向您說明本網站的服務條款，以保障雙方的權益，請您詳閱下列內容：
                        </Typography>

                        <Box sx={{height: 10}}/>
                        <Typography variant={"body1"} sx={{fontStyle: "italic"}}>
                            本條款適用於 Zentring 所提供的所有服務，唯各服務可能會有特定的政策做補充，在使用相關服務前請詳細閱讀該服務的政策。
                        </Typography>

                        <Box sx={{height: 10}}/>


                        <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                            帳戶
                        </Typography>

                        <Box sx={{ml: 4}}>
                            <Typography variant={"body1"}>
                                當您造訪或使用 Zentring 所提供之功能服務時，
                                將會記錄相關資訊至您的 Zentring 帳戶（如果未註冊或登入則記錄相關識別資訊），
                                包括但不限於 IP 位址、瀏覽器、作業系統、使用時間等，以提供更好的服務品質。<br/>
                                大部分的功能需要您註冊或登入 Zentring 帳戶， 註冊時我們會要求您提供個人資料，包括但不限於姓名、性別、生日等，
                                以確保您的帳戶安全，並提供更好的服務品質。<br/>

                                您可以隨時在
                                <a className={classes.link} href={"//account.zentring.net"}>
                                    帳戶系統
                                    <OpenInNewIcon sx={{fontSize: 14}}/>
                                </a> 自行操作，更正、刪除您的帳戶或各服務所蒐集的個人資料等隱私資訊。<br/>
                            </Typography>
                        </Box>


                        <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                            資料之保護
                        </Typography>
                        <Typography variant={"body1"} sx={{ml: 5}}>
                            Zentring 所有服務皆位於自建機房內，均設有防火牆、防毒系統等各項設備，以保護您的個人資料<br/>
                            採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。<br/>
                            如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。<br/>
                        </Typography>


                        <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                            網站對外的相關連結
                        </Typography>
                        <Typography variant={"body1"} sx={{ml: 5}}>
                            本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。<br/>
                        </Typography>


                        <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                            與第三人共用個人資料之政策
                        </Typography>
                        <Typography variant={"body1"} sx={{ml: 5}}>
                            本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。<br/>
                            前項但書之情形包括不限於：
                            <ol>
                                <li>經由您書面同意</li>
                                <li>法律明文規定</li>
                                <li>為免除您生命、身體、自由或財產上之危險</li>
                                <li>與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人</li>
                                <li>當您在網站的行為，違反服務條款、可能損害或妨礙網站與其他使用者權益、導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者</li>
                                <li>有利於您的權益</li>
                                <li>本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。</li>
                            </ol>
                        </Typography>


                        <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                            Cookie 之使用
                        </Typography>
                        <Typography variant={"body1"} sx={{ml: 5}}>
                            為了維持運作，本網站會在電腦中放置和使用 Cookie ，若不願接受 Cookie 的寫入，可在瀏覽器設定中拒絕
                            Cookie 存取，但會導致無法登入等相關問題。<br/>
                        </Typography>


                        <Typography variant={"h2"} sx={{fontSize: 20, fontWeight: "bold", mt: 2, mb: 2}}>
                            服務條款之修正
                        </Typography>
                        <Typography variant={"body1"} sx={{ml: 5}}>
                            服務條款將因應需求隨時進行修正，修正後的條款將更新於此頁面，並以電子郵件另行通知會員。<br/>
                        </Typography>

                        <Typography variant={"body1"} sx={{mt: 5}}>
                            如有未盡事宜，依有關法令、習慣及誠信與平等互惠原則公平解決之
                        </Typography>

                        <Box sx={{mt: 5}}/>

                        <Typography variant={"body2"}>
                            天日科技有限公司<br/>
                            Zentring LTD.<br/>
                            {date}
                        </Typography>
                    </Container>
                </div>
                <Box sx={{height: 10}}/>
            </div>
        );
    }
}

export default withRouter(withStyles(classes)(Tos));
