import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import enUS from "./lang/en-US.json";
import zhCN from "./lang/zh-CN.json";
import zhTW from "./lang/zh-TW.json";

let resources = {
    "en-US": {
        translation: enUS,
    },
    "zh-CN": {
        translation: zhCN,
    },
    "zh-TW": {
        translation: zhTW,
    },
};

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en-US',
        debug: false,
        lng: "en-US",
        resources,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;