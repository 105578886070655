import React from 'react';
import {Link} from "react-router-dom";
import {Container} from "@mui/material";
import {withRouter} from "../withRouter";
import withStyles from "react-jss";

const classes = theme => ({
    link: {
        color: "#000",
        textDecoration: "none",
        display: "block",
        width: "max-content",
    },
    footerCell: {
        flex: 1,
        minWidth: 150,
        marginBottom: 15,
        "& > *": {
            marginTop: 10,
        }
    }
});

class Footer extends React.Component {

    constructor(prop) {
        super(prop);
        this.props = prop;
        this.state = {}
    }

    render() {
        const {classes} = this.props;
        return (
            <div style={{marginTop: 4}}>
                <Container>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                    }}>
                        <div className={classes.footerCell}>
                            <a className={classes.link} href={"//account.zentring.net/"}>帳戶</a>
                            <a className={classes.link} href={"//wallet.zentring.net/"}>錢包 (X)</a>
                            <a className={classes.link} href={"//hosting.zentring.net/"}>Hosting</a>
                        </div>
                        <div className={classes.footerCell}>
                            <Link className={classes.link} to={"/develop"}>軟硬體開發</Link>
                            <a className={classes.link} href={"//developers.zentring.net"}>開發人員中心 (X)</a>
                            <a className={classes.link} href={"//store.zentring.net/"}>商店 (X)</a>
                        </div>
                        <div className={classes.footerCell}>
                            <a className={classes.link} href={"//iot.zentring.net"}>智慧家電 (X)</a>
                            <a className={classes.link} href={""}>茶壺 (X)</a>
                            <a className={classes.link} href={""}>Minecraft (X)</a>
                        </div>
                        <div className={classes.footerCell}>
                            <a className={classes.link} href={"//support.zentring.net"}>支援</a>
                            <Link className={classes.link} to={"/tos"}>服務條款</Link>
                            <Link className={classes.link} to={"/contact"}>聯繫我們</Link>
                            <Link className={classes.link} to={"/about"}>關於 Zentring</Link>
                        </div>
                    </div>
                    <div style={{marginTop: 0, fontStyle: "italic"}}>
                        上方標示 (X) 之服務或頁面尚未開放，敬請期待。
                    </div>
                    <div style={{marginTop: 10, marginBottom: 10}}>
                        Copyright ©{(new Date()).getFullYear()} Zentring LTD. All rights reserved.
                    </div>
                </Container>
            </div>
        );
    }
}

export default withRouter(withStyles(classes)(Footer));
