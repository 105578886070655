import React from 'react';
import monitor from "../assets/monitor.png";
import PCB from "../assets/PCB.png";
import rxshop from "../assets/rxshop.png";
import {withRouter} from "../withRouter";
import {Box, Grid, Typography} from "@mui/material";
import withStyles from "react-jss";
import {withTheme} from "../withTheme";
import {Link} from "react-router-dom";

const classes = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: "#000",
        textDecoration: "none",
        verticalAlign: "middle",
        lineHeight: "40px",
        display: "inline-block",
        marginLeft: 10,
        marginRight: 10,
    },
});

class Develop extends React.Component {

    constructor(prop) {
        super(prop);
        this.props = prop;
        this.state = {
            xml: "",
            monitorScaleFactor: 1,
        }
        this.updateMonitorImageScale.bind(this);
        this.cartSection = React.createRef()
    }


    componentDidMount() {
        window.scrollTo(0, 0);

        window.onscroll = (e) => {
            let a = this.cartSection.current.getBoundingClientRect().top
            if (a < 50) {
                this.props.onUpdateColor("rgba(255, 255, 255, 1)")
            } else {
                this.props.onUpdateColor("rgba(0, 0, 0, 0)")
            }
        }
        window.onscroll(null)

        window.onresize = () => {
            this.updateMonitorImageScale();
        }
        this.updateMonitorImageScale();
    }

    updateMonitorImageScale = () => {
        // console.log(window.innerWidth);
        if (window.innerWidth < 600) {
            this.setState({monitorScaleFactor: 1})
        } else if (window.innerWidth < 900) {
            this.setState({monitorScaleFactor: 1.2})
        } else if (window.innerWidth < 1200) {
            this.setState({monitorScaleFactor: 1.5})
        } else if (window.innerWidth < 1536) {
            this.setState({monitorScaleFactor: 1.5})
        } else {
            this.setState({monitorScaleFactor: 2})
        }
    }

    componentWillUnmount() {
        window.onscroll = null;
        window.onresize = null;
    }

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: 650,


                        position: "relative",
                        "&:before": {
                            content: '""',
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundImage: "url(" + monitor + ")",
                            filter: "brightness(40%)",
                            left: 0, top: 0, bottom: 0, right: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: -1,
                            position: "absolute",
                        }
                    }}>
                    <Grid item sx={{}}>
                        {/*<Grid item sx={{backdropFilter: "blur(5px)"}}>*/}
                        <Typography variant={"h2"} sx={{
                            textAlign: "center",
                            textShadow: "1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000"
                        }} color={"white"}>
                            服務項目
                        </Typography>
                        <Typography variant={"h4"} sx={{
                            mt: 2,
                            textAlign: "center",
                            textShadow: "1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000"
                        }} color={"white"}>
                            餐廳 POS 系統、車牌辨識系統、ERP 系統、
                            <br/>
                            單晶片解決方案、App 開發
                        </Typography>
                    </Grid>
                </Grid>


                <Box sx={{
                    backgroundColor: "#f5f5f5",
                }} ref={this.cartSection}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{height: 650,}}>
                            <Box sx={{}}>
                                <Box sx={{
                                    // position: "relative",
                                    width: "80%",
                                    height: 650,
                                    margin: "auto",
                                }}>
                                    <Box sx={{
                                        top: "50%",
                                        left: "50%",
                                        width: 384 * this.state.monitorScaleFactor,//1920 384
                                        height: 184 * this.state.monitorScaleFactor,//920 184
                                        maxWidth: "900%",
                                        border: "solid 10px black",
                                        borderRadius: 3,
                                        position: "relative",
                                        transform: "translate(-50%, -50%)",
                                    }}>
                                        <Box sx={{
                                            position: "absolute",
                                            width: "40%",
                                            left: "50%",
                                            transform: "translate(-50%, 0)",
                                            height: "8%",
                                            bottom: "-20%",
                                            backgroundColor: "black",
                                        }}/>

                                        <Box sx={{
                                            position: "absolute",
                                            width: "10%",
                                            left: "50%",
                                            transform: "translate(-50%, 0)",
                                            height: "20%",
                                            bottom: "-20%",
                                            backgroundColor: "black",
                                        }}/>

                                        <img src={rxshop} alt={"Zentring cloud"}
                                             style={{
                                                 width: "100%",
                                                 userSelect: "none",
                                                 position: "relative",
                                             }}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{height: 650,}}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="stretch"
                                sx={{
                                    backgroundColor: "#f5f5f5",
                                    height: "100%",
                                }}>
                                <Grid item>
                                    <Typography variant={"h2"} sx={{
                                        textAlign: "center",
                                    }} color={""}>
                                        購物車系統
                                    </Typography>
                                    <Typography variant={"h4"} sx={{
                                        mt: 2,
                                        textAlign: "center",
                                    }} color={""}>
                                        擁有自己的購物車，不需支付第三方平台費用
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>


                <Box sx={{
                    backgroundColor: "#f5f5f5",
                }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{height: 650,}}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="stretch"
                                sx={{
                                    backgroundColor: "#f5f5f5",
                                    height: "100%",
                                }}>
                                <Grid item>
                                    <Typography variant={"h2"} sx={{
                                        textAlign: "center",
                                    }} color={""}>
                                        電路解決方案
                                    </Typography>
                                    <Typography variant={"h5"} sx={{
                                        mt: 2,
                                        textAlign: "center",
                                    }} color={""}>
                                        我們擁有豐富的 PCB Layout 和 STM32 MCU 開發經驗
                                        <br/>開發新產品不是夢
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{height: 650,}}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="stretch"
                                sx={{
                                    backgroundColor: "#f5f5f5",
                                    height: "100%",
                                }}>
                                <Grid item sx={{textAlign: "center"}}>
                                    <img src={PCB} alt={"Zentring cloud"}
                                         style={{
                                             height: 600,
                                             userSelect: "none",
                                         }}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{
                    position: "relative",
                    height: 650, overflowY: "hidden",
                }}>
                    <video autoPlay loop muted style={{
                        width: "100%", position: "absolute",
                        filter: "brightness(45%)",
                    }}>
                        <source src="/assets/360_Parking.mp4" type="video/mp4"/>
                    </video>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            height: 650,
                            position: "absolute"
                        }}>
                        <Grid item sx={{}}>
                            <Typography variant={"h2"} sx={{
                                textAlign: "center",
                                textShadow: "1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000"
                            }} color={"white"}>
                                車牌辨識
                            </Typography>
                            <Typography variant={"h4"} sx={{
                                mt: 2,
                                textAlign: "center",
                                mb: 8,
                                textShadow: "1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000"
                            }} color={"white"}>
                                可整合於現有閘門機，僅需安裝一台主機及攝影機
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{
                    height: 650,
                    // backgroundImage: "linear-gradient(#f5f5f5, #FFFFFF)",
                }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        sx={{
                            height: "100%",
                        }}>
                        <Grid item>
                            <Typography variant={"h2"} sx={{
                                textAlign: "center",
                            }} color={""}>
                                感興趣嗎？
                            </Typography>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="stretch"
                                sx={{
                                    height: "100%",
                                    mt: 2,
                                }}>
                                <Grid item>
                                    <Typography variant={"h5"} sx={{}} color={""}>
                                        我們的辦公室位於<br/>
                                        高雄市苓雅區自強三路 5 號（85大樓）<br/>
                                        29 樓 05 室
                                    </Typography>
                                    <Typography variant={"h5"} sx={{mt: 4}} color={""}>
                                        或是打個電話<br/>
                                        +886 7 566 6191
                                    </Typography>
                                    <Typography variant={"h5"} sx={{mt: 4}} color={""}>
                                        營業時間<br/>
                                        週一至週日 10:00-25:00
                                    </Typography>

                                    <Box sx={{height: 20}}/>

                                    <Box sx={{width: "100%", margin: "auto"}}>
                                        <Link style={{color: "black", width: "70%", margin: "auto", display: "block",}}
                                           className={classes.link}
                                           to={"/contact"}>
                                            <div
                                                style={{
                                                    border: "1px solid rgba(0, 0, 0, .7)",
                                                    padding: "10px 50px",
                                                    borderRadius: 10,
                                                    // maxWidth: "90%",
                                                }}>
                                                <Typography variant={"h5"} align={"center"}
                                                            style={{verticalAlign: "middle",}}>
                                                    聯繫我們
                                                </Typography>
                                            </div>
                                        </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Box>
                <Box sx={{height: 10}}/>
            </div>
        );
    }
}

export default withTheme(withRouter(withStyles(classes)(Develop)));
