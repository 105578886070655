import {useEffect, useRef} from "react";
import useScreenSize from "../useScreenSize";


function drawCube(ctx, x, y, sizeX, sizeY, sizeZ, color) {
    // left face
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x - sizeX, y - sizeX * 0.5);
    ctx.lineTo(x - sizeX, y - sizeZ - sizeX * 0.5);
    ctx.lineTo(x, y - sizeZ * 1);
    ctx.closePath();
    ctx.fillStyle = "#466771"
    ctx.strokeStyle = "#466771";
    ctx.stroke();
    ctx.fill();

    // right face
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + sizeY, y - sizeY * 0.5);
    ctx.lineTo(x + sizeY, y - sizeZ - sizeY * 0.5);
    ctx.lineTo(x, y - sizeZ * 1);
    ctx.closePath();
    ctx.fillStyle = "#365761";
    ctx.strokeStyle = "#365761";
    ctx.stroke();
    ctx.fill();

    // center face
    ctx.beginPath();
    ctx.moveTo(x, y - sizeZ);
    ctx.lineTo(x - sizeX, y - sizeZ - sizeX * 0.5);
    ctx.lineTo(x - sizeX + sizeY, y - sizeZ - (sizeX * 0.5 + sizeY * 0.5));
    ctx.lineTo(x + sizeY, y - sizeZ - sizeY * 0.5);
    ctx.closePath();
    ctx.fillStyle = "#7697A1";
    ctx.strokeStyle = "#7697A1";
    ctx.stroke();
    ctx.fill();
}

function drawOfficeHelper(ctx) {
    ctx.beginPath();
    ctx.moveTo(315, 284);
    ctx.lineTo(150, 300);
    ctx.stroke();
    ctx.font = "24px serif";
    ctx.fillText("辦公室位置", 20, 300);
}

function drawDoorHelper(ctx) {
    ctx.beginPath();
    ctx.moveTo(265, 416);
    ctx.lineTo(150, 400);
    ctx.stroke();
    ctx.font = "24px serif";
    ctx.fillText("大門位置", 40, 400);
}

function draw85(ctx) {

    drawCube(ctx, 380, 490, 195, 65, 50);

    drawCube(ctx, 250, 380, 65, 65, 190);//Left
    drawCube(ctx, 315, 280, 65, 65, 190);//Top
    drawCube(ctx, 380, 445, 65, 65, 190);//Right

    drawPlate(ctx, 265, 400, 32, 32, "red");//Door
    drawPlate(ctx, 315, 280, 8, 8, "red");//Office

    drawOfficeHelper(ctx)
    drawDoorHelper(ctx)
}

function drawPlate(ctx, x, y, width, height, color) {
    // 左上頂點
    const topLeft = {x: x, y: y};
    // 右上頂點
    const topRight = {x: x + width, y: y + width * 0.5};
    // 右下頂點
    const bottomRight = {x: x + width, y: y + height + width * 0.5};
    // 左下頂點
    const bottomLeft = {x: x, y: y + height};

    // 繪製平面
    ctx.beginPath();
    ctx.moveTo(topLeft.x, topLeft.y);
    ctx.lineTo(topRight.x, topRight.y);
    ctx.lineTo(bottomRight.x, bottomRight.y);
    ctx.lineTo(bottomLeft.x, bottomLeft.y);
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.fill();
}

export default function BuildingCanvas(props) {

    const canvasRef = useRef(null)

    const screenSize = useScreenSize();

    // const draw = ctx => {
    //     ctx.fillStyle = '#000000'
    //     ctx.beginPath()
    //     ctx.arc(50, 100, 20, 0, 2 * Math.PI)
    //     ctx.fill()
    // }

    useEffect(() => {

        const canvas = canvasRef.current
        const context = canvas.getContext('2d')

        canvas.width = canvas.clientWidth
        canvas.height = canvas.clientHeight

        // context.scale(2, 2);
        draw85(context)

        // draw85(context)
    }, [screenSize.width])


    return <canvas ref={canvasRef} {...props}/>
}