import React from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container, FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {withRouter} from "../withRouter";
import withStyles from "react-jss";
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import {Link} from "react-router-dom";

const classes = theme => ({
    link: {
        color: "#000",
        textDecoration: "none",
        display: "block",
        width: "max-content",
    },
});

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            name: "",
            phone: "",
            email: "",
            message: "",
            company: "",
            service: "none",

            isSendingMessage: false,
            isMessageSent: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.onUpdateColor("rgba(255, 255, 255, 1)")

        //Check the GET parameter to select the service
        const urlParams = new URLSearchParams(window.location.search);
        const service = urlParams.get('service');
        if (service) {
            this.setState({service: service})
        }
    }

    render() {
        const {classes} = this.props;
        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };

        let isAllInputValid = this.state.name.length > 0 && this.state.phone.length > 0 && this.state.email.length > 0 && this.state.message.length > 0 && this.state.email.includes("@") && this.state.email.includes(".");
        let isEmailValid = this.state.email.length > 0 && !validateEmail(this.state.email);

        return (
            <div>
                <div style={{height: 50}}/>
                <div style={{marginTop: 20, marginBottom: 20,}}>
                    <Container>
                        <Typography variant={"h4"}>
                            聯繫我們
                        </Typography>

                        {!this.state.isMessageSent ? <>

                            <Typography variant={"body1"} sx={{mt: 2}}>
                                如果您有任何問題歡迎致電我們，
                            </Typography>
                            <Typography variant={"body1"} sx={{mt: 1}}>
                                <a className={classes.link} href={"tel:+886 07 566 6191"}>
                                    07 566 6191
                                </a>
                            </Typography>
                            <Typography variant={"body1"} sx={{mt: 2}}>
                                或是寫封信給我們，
                            </Typography>
                            <Typography variant={"body1"} sx={{mt: 1}}>
                                <a className={classes.link} href={"mailto:service@zentring.net"}>
                                    service@zentring.net
                                </a>
                            </Typography>
                            <Typography variant={"body1"} sx={{mt: 3}}>
                                也可以填寫以下表格，我們將盡快回覆您。
                            </Typography>

                            <Box width={640} maxWidth={"100%"}>
                                <Box sx={{height: 20}}/>
                                <TextField fullWidth label="姓名" variant="outlined" value={this.state.name}
                                           onChange={(e) => {
                                               this.setState({name: e.target.value})
                                           }}/>
                                <Box sx={{height: 20}}/>
                                <TextField fullWidth label="電話" variant="outlined" value={this.state.phone}
                                           onChange={(e) => {
                                               this.setState({phone: e.target.value})
                                           }}/>

                                <Box sx={{height: 20}}/>
                                <TextField fullWidth label="公司名稱 (可選)" variant="outlined"
                                           value={this.state.company}
                                           onChange={(e) => {
                                               this.setState({company: e.target.value})
                                           }}/>

                                <Box sx={{height: 20}}/>
                                <TextField fullWidth label="Email" variant="outlined" value={this.state.email}
                                           onChange={(e) => {
                                               this.setState({email: e.target.value})
                                           }}
                                           error={isEmailValid}
                                           helperText={isEmailValid && "Email 格式不正確"}/>
                                <Box sx={{height: 20}}/>

                                <FormControl fullWidth>
                                    <InputLabel>所屬服務</InputLabel>
                                    <Select value={this.state.service} onChange={(e) => {
                                        this.setState({service: e.target.value})
                                    }}>
                                        <MenuItem value={"none"}>無</MenuItem>
                                        <MenuItem value={"account"}>Zentring 帳戶</MenuItem>
                                        <MenuItem value={"store"}>Zentring 商店</MenuItem>
                                        <MenuItem value={"hosting"}>虛擬化及其他雲端服務</MenuItem>
                                        <MenuItem value={"console"}>Zentring Console</MenuItem>
                                    </Select>
                                </FormControl>

                                <Box sx={{height: 20}}/>
                                <TextField fullWidth label="內文" variant="outlined" multiline rows={6}
                                           value={this.state.message}
                                           onChange={(e) => {
                                               this.setState({message: e.target.value})
                                           }}/>

                                <Box sx={{height: 20}}/>


                                <Box textAlign={"right"}>
                                    <Button variant="contained" color="success" onClick={() => {
                                        this.setState({isSendingMessage: true})
                                        let formData = new FormData();
                                        formData.append("name", this.state.name);
                                        formData.append("phone", this.state.phone);
                                        formData.append("company", this.state.company);
                                        formData.append("email", this.state.email);
                                        formData.append("message", this.state.message);
                                        formData.append("service", this.state.service);
                                        axios.post("/api/contact", formData, {
                                            withCredentials: true
                                        }).then(r => {

                                            this.setState({isSendingMessage: false})
                                            if (r.data.status) {
                                                this.setState({isMessageSent: true})
                                            } else {

                                            }
                                            console.log(r.data)
                                        })
                                    }} sx={{position: "relative"}}
                                            disabled={this.state.isSendingMessage || !isAllInputValid}>
                                        {this.state.isSendingMessage &&
                                            <CircularProgress size={20} sx={{position: "absolute"}}/>}

                                        <SendIcon sx={{mr: 2}} fontSize={"small"}/>
                                        送出
                                    </Button>
                                </Box>
                            </Box>
                        </> : <>
                            <Box sx={{mt: 2, height: 500}}>
                                <Alert severity="success">訊息已送出，將會儘速回覆您</Alert>
                                <Link style={{marginTop: 16}} className={classes.link} to={"/"}>回首頁</Link>
                            </Box>
                        </>}

                    </Container>
                </div>
                <Box sx={{height: 10}}/>
            </div>
        );
    }
}

export default withRouter(withStyles(classes)(Contact));
